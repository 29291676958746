select {
    /* Reset */
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    /* Personalize */
    width: 20em;
    height: 3em;
    padding: 0 0 0 0.5em;
    background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg) no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, rgba(229, 161, 175, 0.93) 3em, rgb(236, 223, 227) 3em);
    color: rgb(32, 31, 36);
    border-radius: 0.25em;
    box-shadow: 0 0 1em 0 rgb(215, 203, 201);
    cursor: pointer;
    transition: opacity 0.3s;  /* Add this line */
}

select:hover {
    opacity: 0.8;  /* Add this line */
}

select option {
    color: inherit;
    background-color: #d9828e;
}

select option:hover {
    opacity: 0.8;  /* Add this line */
}

select:focus {
    outline: none;
}

select::-ms-expand {
    display: none;
}
