.hidden { display: none; }
.o-red { outline: 1px dashed red; }
.b1 {
  color: rgb(32, 31, 36);
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(81, 66, 95, 0.09);
  border: 1px solid rgba(81, 66, 95, 0.18);
  cursor: pointer;
}

body {
  font-family: 'QuicksandMedium', cursive;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* Extra small devices (phones, 320px and up) */
@media (min-width: 320px) {
  .landing { font-size: 1em; }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .landing { font-size: 4em; }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .landing { font-size: 8em; }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .landing { font-size: 10em; }
}

@font-face {
  font-family: 'Times';
  src: local('TIMES'), url(../fonts/TIMES.TTF-16-Regular.woff) format('woff');
}

@font-face {
  font-family: 'TimesBold';
  src: local('TIMES'), url(../fonts/TIMESBD.TTF-16-Bold.woff) format('woff');
}

.times-bold { font-family: 'TimesBold', cursive; }

@font-face {
  font-family: 'TimesItalic';
  src: local('TIMES'), url(../fonts/TIMESI.TTF-16-Italic.woff) format('woff');
}

.times-italic { font-family: 'TimesItalic', cursive; }

@font-face {
  font-family: 'TheCartel';
  src: local('THECARTEL'), url(../fonts/The-Cartel.woff) format('woff');
}

.the-cartel { font-family: 'TheCartel', cursive; }

@font-face {
  font-family: 'QuicksandSemiBold';
  src: local('QUICKSANDSEMIBOLD'), url(../fonts/Quicksand-SemiBold.woff) format('woff');
}

.quicksand-semi-bold { font-family: 'QuicksandSemiBold', cursive; }

@font-face {
  font-family: 'QuicksandMedium';
  src: local('QUICKSANDMEDIUM'), url(../fonts/Quicksand-Medium.woff) format('woff');
}

.quicksand-medium { font-family: 'QuicksandMedium', cursive; }

.vh-foo {
  height: 75vh
}